<template>
  <div>
    <v-row v-for="project in projects" :key="project.name" class="ma-0 mb-8">
      <project-card :value="project" @click:copy="openCopyDialog($event)"></project-card>
    </v-row>

    <project-copy-dialog v-if="dialog" v-model="dialog" :projectId="currentProjectId" :projectName="currentProjectName" />
  </div>
</template>

<script>
import ProjectCard from '../molecules/ProjectCard'
import ProjectCopyDialog from './ProjectCopyDialog.vue'

export default {
  props: ["value"],
  components: {
    ProjectCard,
    ProjectCopyDialog,
  },
  data() {
    return {
      projects: null,

      dialog: false,
      currentProjectId: null,
      currentProjectName: null,
    }
  },
  created() {
    if (this.value) {
      this.projects = this.value;
    }
  },
  computed: {
  },
  methods: {
    openCopyDialog(e) {
      this.currentProjectId = e.projectId;
      this.currentProjectName = e.projectName;
      this.dialog = true;
    }
  },
  watch: {
    value(val) {
      this.projects = val;
    },
  },
}
</script>
