<template>
  <div>
    <!-- 複製確認 -->
    <v-dialog v-model="value" @input="$emit('input', $event)" @click:outside="$emit('input', false)" width="600">
      <v-card>
        <v-card-title>プロジェクトを複製する（{{ step }}/2）</v-card-title>
        <v-divider></v-divider>
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text class="text-subtitle-2 pt-6 px-6">
              <div class="black--text mb-4 text-center">
                現在のプロジェクトを複製して、新たなプロジェクトを作成します。
              </div>
              <v-text-field
                v-model="newName"
                label="新しいプロジェクト名"
                autofocus
              ></v-text-field>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="2">
            <v-card-text class="text-subtitle-2 py-6 px-6">
              <div class="black--text text-center">
                複製するパーツを選択してください。
              </div>
              <div class="d-flex justify-center">
                <v-checkbox
                  :label="!isAll ? '全て選択' : '全て外す'"
                  v-model="isAll"
                  hide-details
                ></v-checkbox>
              </div>
              <div class="d-flex flex-wrap">
                <v-checkbox
                  v-for="(item, i) in sections"
                  :key="i"
                  v-model="selected"
                  :label="item.section.label"
                  :value="item.section.sectionId"
                  color="btPrimary"
                  hide-details
                  class="me-4"
                ></v-checkbox>
              </div>
            </v-card-text>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step == 1"
            class="mx-2"
            depressed
            text
            min-width="190"
            @click="$emit('input', false)"
          >
            閉じる
          </v-btn>
          <v-btn
            v-else
            class="mx-2"
            depressed
            text
            min-width="190"
            @click="step--"
          >
            前へ
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="step == 2"
            color="btAccent"
            class="white--text mx-2"
            depressed
            min-width="190"
            @click="copyYes()"
          >
            複製する
          </v-btn>
          <v-btn
            v-else
            color="btAccent"
            class="white--text mx-2"
            depressed
            min-width="190"
            @click="step++"
          >
            次へ
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <yes-no-dialog
      v-model="copyDoneDialog"
      message="複製しました。複製したプロジェクトに移動しますか？"
      yesText="移動する"
      noText="移動しない"
      @clickYes="copyDoneYes()"
      @clickNo="copyDoneNo()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "../../../api/RepositoryFactory";

import YesNoDialog from "../atoms/YesNoDialog.vue";

export default {
  components: {
    YesNoDialog,
  },

  props: {
    value: Boolean,
    projectId: Number,
    projectName: String,
  },

  data() {
    return {
      newName: null,
      selected: [],

      copiedProject: {},
      copyDialog: false,
      copyDoneDialog: false,

      step: 1,
      sections: [],
    };
  },

  computed: {
    ...mapGetters(["project", "userInfo", "registeredSections"]),

    isAll: {
      get() {
        // パーツ選択肢が全選択されているときのみtrueを返す
        if (this.selected.length == this.sections.length) {
          return true;
        } else {
          return false;
        }
      },
      set(val) {
        // パーツ選択肢が全選択されているときのみチェックを外す
        if (this.selected.length == this.sections.length) {
          this.selected = [];
        } else {
          this.selectAll();
        }
      },
    },
  },

  methods: {
    ...mapActions(["getRegisteredSections", "getProject"]),

    async copyYes() {
      // コピー対象のプロジェクト情報を取得する
      console.log(this.projectId);
      await this.getProject(this.projectId);
      
      let body = {
        project: {
          projectName: !!this.newName ? this.newName : this.project.projectName,
        },
        sections: this.selected,
        userId: this.userInfo.userId,
      };

      const repo = RepositoryFactory.get("projectCopy");
      const response = await repo.copyProject(this.project.id, body);
      if (response.data.result && response.data.resultCode === "0000") {
        console.log("copyProject response:", response);
        this.copiedProject = response.data.data.project;
        this.copyDoneDialog = true;
      } else {
        this._sendError(response.data.message);
      }
    },

    copyDoneYes() {
      this.copyDoneDialog = false;
      this.$router.push(`/strategy/${this.copiedProject.id}/project/edit`)
      this.$emit('input', false);
    },

    async copyDoneNo() {
      this.copyDoneDialog = false;
      this.$router.push(`/top`)
      this.$emit('input', false);
    },

    selectAll() {
      this.selected = this.sections.map((item) => item.section.sectionId);
    },
  },
  async created() {
    this.newName = `${this.projectName}のコピー`;

    await this.getRegisteredSections(this.projectId);
    this.sections = this.registeredSections.sections;
  },
};
</script>
